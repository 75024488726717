import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function AllRoundedButtonSwitchers(props) {
  const {
    ButtonSwitchersRoundedLgBasic,
    ButtonSwitchersRoundedLgBasicLeadingIcon,
    ButtonSwitchersRoundedLgBasicTrailingIcon,
    ButtonSwitchersRoundedBaseBasic,
    ButtonSwitchersRoundedBaseBasicLeadingIcon,
    ButtonSwitchersRoundedBaseBasicTrailingIcon,
    ButtonSwitchersRoundedSmBasic,
    ButtonSwitchersRoundedSmBasicLeadingIcon,
    ButtonSwitchersRoundedSmBasicTrailingIcon,
  } = props
  return (
    <div className="flex flex-col items-start gap-20">
      <div className="flex flex-col items-start gap-10">
        <CopyComponent
          copyToClipboardCode={ButtonSwitchersRoundedLgBasic}
          componentName="ButtonSwitchersRoundedLgBasic"
        >
          {/*<!-- Component: Basic lg sized button switch --> */}
          <div className="group relative inline-flex items-center rounded bg-slate-100">
            <input
              className="peer order-2 hidden"
              type="checkbox"
              value=""
              id="id-c001"
            />
            {/*  <!-- First label --> */}
            <label
              className="order-1 inline-flex h-12 cursor-pointer items-center justify-center gap-2 rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition-colors hover:bg-emerald-600 focus:bg-emerald-700 peer-checked:bg-transparent peer-checked:text-slate-500 hover:peer-checked:bg-transparent peer-checked:hover:text-slate-600 focus:peer-checked:bg-transparent"
              htmlFor="id-c001"
            >
              Code mode
            </label>
            {/*  <!-- Second label --> */}
            <label
              className="order-1 inline-flex h-12 cursor-pointer items-center justify-center gap-2 rounded bg-transparent px-6 text-sm font-medium tracking-wide text-slate-500 transition-colors hover:text-slate-600 peer-checked:bg-emerald-500 peer-checked:text-white hover:peer-checked:bg-emerald-600 peer-checked:hover:text-white focus:peer-checked:bg-emerald-700"
              htmlFor="id-c001"
            >
              Visual mode
            </label>
          </div>
          {/*<!-- End Basic lg sized button switch --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ButtonSwitchersRoundedBaseBasic}
          componentName="ButtonSwitchersRoundedBaseBasic"
        >
          {/*<!-- Component: Basic base sized button switch --> */}
          <div className="group relative inline-flex items-center rounded bg-slate-100">
            <input
              className="peer order-2 hidden"
              type="checkbox"
              value=""
              id="id-c002"
            />
            {/*  <!-- First label --> */}
            <label
              className="order-1 inline-flex h-10 cursor-pointer items-center justify-center gap-2 rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition-colors hover:bg-emerald-600 focus:bg-emerald-700 peer-checked:bg-transparent peer-checked:text-slate-500 hover:peer-checked:bg-transparent peer-checked:hover:text-slate-600 focus:peer-checked:bg-transparent"
              htmlFor="id-c002"
            >
              Code mode
            </label>
            {/*  <!-- Second label --> */}
            <label
              className="order-1 inline-flex h-10 cursor-pointer items-center justify-center gap-2 rounded bg-transparent px-5 text-sm font-medium tracking-wide text-slate-500 transition-colors hover:text-slate-600 peer-checked:bg-emerald-500 peer-checked:text-white hover:peer-checked:bg-emerald-600 peer-checked:hover:text-white focus:peer-checked:bg-emerald-700"
              htmlFor="id-c002"
            >
              Visual mode
            </label>
          </div>
          {/*<!-- End Basic base sized button switch --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ButtonSwitchersRoundedSmBasic}
          componentName="ButtonSwitchersRoundedSmBasic"
        >
          {/*<!-- Component: Basic sm sized button switch --> */}
          <div className="group relative inline-flex items-center rounded bg-slate-100">
            <input
              className="peer order-2 hidden"
              type="checkbox"
              value=""
              id="id-c003"
            />
            {/*  <!-- First label --> */}
            <label
              className="order-1 inline-flex h-8 cursor-pointer items-center justify-center gap-2 rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition-colors hover:bg-emerald-600 focus:bg-emerald-700 peer-checked:bg-transparent peer-checked:text-slate-500 hover:peer-checked:bg-transparent peer-checked:hover:text-slate-600 focus:peer-checked:bg-transparent"
              htmlFor="id-c003"
            >
              Code mode
            </label>
            {/*  <!-- Second label --> */}
            <label
              className="order-1 inline-flex h-8 cursor-pointer items-center justify-center gap-2 rounded bg-transparent px-4 text-xs font-medium tracking-wide text-slate-500 transition-colors hover:text-slate-600 peer-checked:bg-emerald-500 peer-checked:text-white hover:peer-checked:bg-emerald-600 peer-checked:hover:text-white focus:peer-checked:bg-emerald-700"
              htmlFor="id-c003"
            >
              Visual mode
            </label>
          </div>
          {/*<!-- End Basic sm sized button switch --> */}
        </CopyComponent>
      </div>

      <div className="flex flex-col items-start gap-10">
        <CopyComponent
          copyToClipboardCode={ButtonSwitchersRoundedLgBasicLeadingIcon}
          componentName="ButtonSwitchersRoundedLgBasicLeadingIcon"
        >
          {/*<!-- Component: Basic lg sized button switch with leading icon --> */}
          <div className="group relative inline-flex items-center rounded bg-slate-100">
            <input
              className="peer order-2 hidden"
              type="checkbox"
              value=""
              id="id-c004"
            />
            {/*  <!-- First label --> */}
            <label
              className="order-1 inline-flex h-12 cursor-pointer items-center justify-center gap-2 rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition-colors hover:bg-emerald-600 focus:bg-emerald-700 peer-checked:bg-transparent peer-checked:text-slate-500 hover:peer-checked:bg-transparent peer-checked:hover:text-slate-600 focus:peer-checked:bg-transparent"
              htmlFor="id-c004"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                />
              </svg>
              Code mode
            </label>
            {/*  <!-- Second label --> */}
            <label
              className="order-1 inline-flex h-12 cursor-pointer items-center justify-center gap-2 rounded bg-transparent px-6 text-sm font-medium tracking-wide text-slate-500 transition-colors hover:text-slate-600 peer-checked:bg-emerald-500 peer-checked:text-white hover:peer-checked:bg-emerald-600 peer-checked:hover:text-white focus:peer-checked:bg-emerald-700"
              htmlFor="id-c004"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              Visual mode
            </label>
          </div>
          {/*<!-- End Basic lg sized button switch with leading icon --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ButtonSwitchersRoundedBaseBasicLeadingIcon}
          componentName="ButtonSwitchersRoundedBaseBasicLeadingIcon"
        >
          {/*<!-- Component: Basic base sized button switch with leading icon --> */}
          <div className="group relative inline-flex items-center rounded bg-slate-100">
            <input
              className="peer order-2 hidden"
              type="checkbox"
              value=""
              id="id-c005"
            />
            {/*  <!-- First label --> */}
            <label
              className="order-1 inline-flex h-10 cursor-pointer items-center justify-center gap-2 rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition-colors hover:bg-emerald-600 focus:bg-emerald-700 peer-checked:bg-transparent peer-checked:text-slate-500 hover:peer-checked:bg-transparent peer-checked:hover:text-slate-600 focus:peer-checked:bg-transparent"
              htmlFor="id-c005"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                />
              </svg>
              Code mode
            </label>
            {/*  <!-- Second label --> */}
            <label
              className="order-1 inline-flex h-10 cursor-pointer items-center justify-center gap-2 rounded bg-transparent px-5 text-sm font-medium tracking-wide text-slate-500 transition-colors hover:text-slate-600 peer-checked:bg-emerald-500 peer-checked:text-white hover:peer-checked:bg-emerald-600 peer-checked:hover:text-white focus:peer-checked:bg-emerald-700"
              htmlFor="id-c005"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              Visual mode
            </label>
          </div>
          {/*<!-- End Basic base sized button switch with leading icon --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ButtonSwitchersRoundedSmBasicLeadingIcon}
          componentName="ButtonSwitchersRoundedSmBasicLeadingIcon"
        >
          {/*<!-- Component: Basic sm sized button switch with leading icon --> */}
          <div className="group relative inline-flex items-center rounded bg-slate-100">
            <input
              className="peer order-2 hidden"
              type="checkbox"
              value=""
              id="id-c006"
            />
            {/*  <!-- First label --> */}
            <label
              className="order-1 inline-flex h-8 cursor-pointer items-center justify-center gap-2 rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition-colors hover:bg-emerald-600 focus:bg-emerald-700 peer-checked:bg-transparent peer-checked:text-slate-500 hover:peer-checked:bg-transparent peer-checked:hover:text-slate-600 focus:peer-checked:bg-transparent"
              htmlFor="id-c006"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                />
              </svg>
              Code mode
            </label>
            {/*  <!-- Second label --> */}
            <label
              className="order-1 inline-flex h-8 cursor-pointer items-center justify-center gap-2 rounded bg-transparent px-4 text-xs font-medium tracking-wide text-slate-500 transition-colors hover:text-slate-600 peer-checked:bg-emerald-500 peer-checked:text-white hover:peer-checked:bg-emerald-600 peer-checked:hover:text-white focus:peer-checked:bg-emerald-700"
              htmlFor="id-c006"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              Visual mode
            </label>
          </div>
          {/*<!-- End Basic sm sized button switch with leading icon --> */}
        </CopyComponent>
      </div>

      <div className="flex flex-col items-start gap-10">
        <CopyComponent
          copyToClipboardCode={ButtonSwitchersRoundedLgBasicTrailingIcon}
          componentName="ButtonSwitchersRoundedLgBasicTrailingIcon"
        >
          {/*<!-- Component: Basic lg sized button switch with trailing icon --> */}
          <div className="group relative inline-flex items-center rounded bg-slate-100">
            <input
              className="peer order-2 hidden"
              type="checkbox"
              value=""
              id="id-c007"
            />
            {/*  <!-- First label --> */}
            <label
              className="order-1 inline-flex h-12 cursor-pointer items-center justify-center gap-2 rounded bg-emerald-500 px-6 text-sm font-medium tracking-wide text-white transition-colors hover:bg-emerald-600 focus:bg-emerald-700 peer-checked:bg-transparent peer-checked:text-slate-500 hover:peer-checked:bg-transparent peer-checked:hover:text-slate-600 focus:peer-checked:bg-transparent"
              htmlFor="id-c007"
            >
              Code mode
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                />
              </svg>
            </label>
            {/*  <!-- Second label --> */}
            <label
              className="order-1 inline-flex h-12 cursor-pointer items-center justify-center gap-2 rounded bg-transparent px-6 text-sm font-medium tracking-wide text-slate-500 transition-colors hover:text-slate-600 peer-checked:bg-emerald-500 peer-checked:text-white hover:peer-checked:bg-emerald-600 peer-checked:hover:text-white focus:peer-checked:bg-emerald-700"
              htmlFor="id-c007"
            >
              Visual mode
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </label>
          </div>
          {/*<!-- End Basic lg sized button switch with trailing icon --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ButtonSwitchersRoundedBaseBasicTrailingIcon}
          componentName="ButtonSwitchersRoundedBaseBasicTrailingIcon"
        >
          {/*<!-- Component: Basic base sized button switch with trailing icon --> */}
          <div className="group relative inline-flex items-center rounded bg-slate-100">
            <input
              className="peer order-2 hidden"
              type="checkbox"
              value=""
              id="id-c008"
            />
            {/*  <!-- First label --> */}
            <label
              className="order-1 inline-flex h-10 cursor-pointer items-center justify-center gap-2 rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition-colors hover:bg-emerald-600 focus:bg-emerald-700 peer-checked:bg-transparent peer-checked:text-slate-500 hover:peer-checked:bg-transparent peer-checked:hover:text-slate-600 focus:peer-checked:bg-transparent"
              htmlFor="id-c008"
            >
              Code mode
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                />
              </svg>
            </label>
            {/*  <!-- Second label --> */}
            <label
              className="order-1 inline-flex h-10 cursor-pointer items-center justify-center gap-2 rounded bg-transparent px-5 text-sm font-medium tracking-wide text-slate-500 transition-colors hover:text-slate-600 peer-checked:bg-emerald-500 peer-checked:text-white hover:peer-checked:bg-emerald-600 peer-checked:hover:text-white focus:peer-checked:bg-emerald-700"
              htmlFor="id-c008"
            >
              Visual mode
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </label>
          </div>
          {/*<!-- End Basic base sized button switch with trailing icon --> */}
        </CopyComponent>

        <CopyComponent
          copyToClipboardCode={ButtonSwitchersRoundedSmBasicTrailingIcon}
          componentName="ButtonSwitchersRoundedSmBasicTrailingIcon"
        >
          {/*<!-- Component: Basic sm sized button switch with trailing icon --> */}
          <div className="group relative inline-flex items-center rounded bg-slate-100">
            <input
              className="peer order-2 hidden"
              type="checkbox"
              value=""
              id="id-c009"
            />
            {/*  <!-- First label --> */}
            <label
              className="order-1 inline-flex h-8 cursor-pointer items-center justify-center gap-2 rounded bg-emerald-500 px-4 text-xs font-medium tracking-wide text-white transition-colors hover:bg-emerald-600 focus:bg-emerald-700 peer-checked:bg-transparent peer-checked:text-slate-500 hover:peer-checked:bg-transparent peer-checked:hover:text-slate-600 focus:peer-checked:bg-transparent"
              htmlFor="id-c009"
            >
              Code mode
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5"
                />
              </svg>
            </label>
            {/*  <!-- Second label --> */}
            <label
              className="order-1 inline-flex h-8 cursor-pointer items-center justify-center gap-2 rounded bg-transparent px-4 text-xs font-medium tracking-wide text-slate-500 transition-colors hover:text-slate-600 peer-checked:bg-emerald-500 peer-checked:text-white hover:peer-checked:bg-emerald-600 peer-checked:hover:text-white focus:peer-checked:bg-emerald-700"
              htmlFor="id-c009"
            >
              Visual mode
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
                aria-label="Dashboard icon"
                role="graphics-symbol"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
            </label>
          </div>
          {/*<!-- End Basic sm sized button switch with trailing icon --> */}
        </CopyComponent>
      </div>
    </div>
  )
}
