import React, { useState } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO Image
import ogImage from "../../../images/seo/button-switchers/button-switchers.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

// Preview
import HeroButtonSwitchers from "../../../library/components/button-switchers/react/_preview/heroButtonSwitchers"
import AllRoundedFullButtonSwitchers from "../../../library/components/button-switchers/react/_preview/fully_rounded"
import AllRoundedButtonSwitchers from "../../../library/components/button-switchers/react/_preview/rounded"

// Rounded
// Large
const ButtonSwitchersRoundedLgBasicJsx = require("!!raw-loader!../../../library/components/button-switchers/react/rounded/lg/basic.jsx")
const ButtonSwitchersRoundedLgBasicHTML = require("!!raw-loader!../../../library/components/button-switchers/html/rounded/lg/basic.html")

const ButtonSwitchersRoundedLgBasicLeadingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/rounded/lg/basic-leading_icon.jsx")
const ButtonSwitchersRoundedLgBasicLeadingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/rounded/lg/basic-leading_icon.html")

const ButtonSwitchersRoundedLgBasicTrailingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/rounded/lg/basic-trailing_icon.jsx")
const ButtonSwitchersRoundedLgBasicTrailingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/rounded/lg/basic-trailing_icon.html")

// Base
const ButtonSwitchersRoundedBaseBasicJsx = require("!!raw-loader!../../../library/components/button-switchers/react/rounded/base/basic.jsx")
const ButtonSwitchersRoundedBaseBasicHTML = require("!!raw-loader!../../../library/components/button-switchers/html/rounded/base/basic.html")

const ButtonSwitchersRoundedBaseBasicLeadingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/rounded/base/basic-leading_icon.jsx")
const ButtonSwitchersRoundedBaseBasicLeadingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/rounded/base/basic-leading_icon.html")

const ButtonSwitchersRoundedBaseBasicTrailingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/rounded/base/basic-trailing_icon.jsx")
const ButtonSwitchersRoundedBaseBasicTrailingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/rounded/base/basic-trailing_icon.html")

// Sm
const ButtonSwitchersRoundedSmBasicJsx = require("!!raw-loader!../../../library/components/button-switchers/react/rounded/sm/basic.jsx")
const ButtonSwitchersRoundedSmBasicHTML = require("!!raw-loader!../../../library/components/button-switchers/html/rounded/sm/basic.html")

const ButtonSwitchersRoundedSmBasicLeadingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/rounded/sm/basic-leading_icon.jsx")
const ButtonSwitchersRoundedSmBasicLeadingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/rounded/sm/basic-leading_icon.html")

const ButtonSwitchersRoundedSmBasicTrailingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/rounded/sm/basic-trailing_icon.jsx")
const ButtonSwitchersRoundedSmBasicTrailingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/rounded/sm/basic-trailing_icon.html")

// Rounded Full
// Large
const ButtonSwitchersFullyRoundedLgBasicJsx = require("!!raw-loader!../../../library/components/button-switchers/react/fully-rounded/lg/basic.jsx")
const ButtonSwitchersFullyRoundedLgBasicHTML = require("!!raw-loader!../../../library/components/button-switchers/html/fully-rounded/lg/basic.html")

const ButtonSwitchersFullyRoundedLgBasicLeadingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/fully-rounded/lg/basic-leading_icon.jsx")
const ButtonSwitchersFullyRoundedLgBasicLeadingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/fully-rounded/lg/basic-leading_icon.html")

const ButtonSwitchersFullyRoundedLgBasicTrailingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/fully-rounded/lg/basic-trailing_icon.jsx")
const ButtonSwitchersFullyRoundedLgBasicTrailingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/fully-rounded/lg/basic-trailing_icon.html")

// Base
const ButtonSwitchersFullyRoundedBaseBasicJsx = require("!!raw-loader!../../../library/components/button-switchers/react/fully-rounded/base/basic.jsx")
const ButtonSwitchersFullyRoundedBaseBasicHTML = require("!!raw-loader!../../../library/components/button-switchers/html/fully-rounded/base/basic.html")

const ButtonSwitchersFullyRoundedBaseBasicLeadingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/fully-rounded/base/basic-leading_icon.jsx")
const ButtonSwitchersFullyRoundedBaseBasicLeadingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/fully-rounded/base/basic-leading_icon.html")

const ButtonSwitchersFullyRoundedBaseBasicTrailingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/fully-rounded/base/basic-trailing_icon.jsx")
const ButtonSwitchersFullyRoundedBaseBasicTrailingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/fully-rounded/base/basic-trailing_icon.html")

// Sm
const ButtonSwitchersFullyRoundedSmBasicJsx = require("!!raw-loader!../../../library/components/button-switchers/react/fully-rounded/sm/basic.jsx")
const ButtonSwitchersFullyRoundedSmBasicHTML = require("!!raw-loader!../../../library/components/button-switchers/html/fully-rounded/sm/basic.html")

const ButtonSwitchersFullyRoundedSmBasicLeadingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/fully-rounded/sm/basic-leading_icon.jsx")
const ButtonSwitchersFullyRoundedSmBasicLeadingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/fully-rounded/sm/basic-leading_icon.html")

const ButtonSwitchersFullyRoundedSmBasicTrailingIconJsx = require("!!raw-loader!../../../library/components/button-switchers/react/fully-rounded/sm/basic-trailing_icon.jsx")
const ButtonSwitchersFullyRoundedSmBasicTrailingIconHTML = require("!!raw-loader!../../../library/components/button-switchers/html/fully-rounded/sm/basic-trailing_icon.html")

export default function ButtonSwitchersPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "BasicButtonSwitchers",
      title: "Basic button switchers",
      active_tab: 1,
    },
    {
      component_name: "RoundedButtonSwitchers",
      title: "Rounded Full",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Button Switchers - WindUI Component Library"
        ogtitle="Tailwind CSS Button Switchers - WindUI Component Library"
        description="Button switcher components help people select one of two options or switch between two views. Built with Tailwind CSS by WindUI."
        ogdescription="Button switcher components help people select one of two options or switch between two views. Built with Tailwind CSS by WindUI."
        url="components/button-groups/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Button Switchers, Button Switchers, Button Switcher Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <Helmet
        style={[
          {
            cssText: `
              input:-webkit-autofill,
              input:-webkit-autofill:hover,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0px 1000px #fff inset;
                -webkit-text-fill-color: rgb(100, 116, 139);
              }
            `,
          },
        ]}
      />

      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Button Switchers</h1>
          <p>
            Button switchers help people select one of two options or switch
            between two views.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 px-0 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:py-16 [&>*]:w-full">
              <HeroButtonSwitchers />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          <h3 id="BasicButtonSwitchers">Basic button switchers</h3>

          <PreviewBlock
            id="RoundedButtonSwitchers"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonSwitchersRoundedLgBasicHTML.default}
            JsxComponent={ButtonSwitchersRoundedLgBasicJsx.default}
          >
            <div className="flex w-full items-center justify-center overflow-scroll rounded-xl bg-white px-4 py-16 md:overflow-hidden">
              <AllRoundedButtonSwitchers
                ButtonSwitchersRoundedLgBasic={
                  activeTabs[0].active_tab === 1
                    ? ButtonSwitchersRoundedLgBasicHTML.default
                    : ButtonSwitchersRoundedLgBasicJsx.default
                }
                ButtonSwitchersRoundedLgBasicLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonSwitchersRoundedLgBasicLeadingIconHTML.default
                    : ButtonSwitchersRoundedLgBasicLeadingIconJsx.default
                }
                ButtonSwitchersRoundedLgBasicTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonSwitchersRoundedLgBasicTrailingIconHTML.default
                    : ButtonSwitchersRoundedLgBasicTrailingIconJsx.default
                }
                ButtonSwitchersRoundedBaseBasic={
                  activeTabs[0].active_tab === 1
                    ? ButtonSwitchersRoundedBaseBasicHTML.default
                    : ButtonSwitchersRoundedBaseBasicJsx.default
                }
                ButtonSwitchersRoundedBaseBasicLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonSwitchersRoundedBaseBasicLeadingIconHTML.default
                    : ButtonSwitchersRoundedBaseBasicLeadingIconJsx.default
                }
                ButtonSwitchersRoundedBaseBasicTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonSwitchersRoundedBaseBasicTrailingIconHTML.default
                    : ButtonSwitchersRoundedBaseBasicTrailingIconJsx.default
                }
                ButtonSwitchersRoundedSmBasic={
                  activeTabs[0].active_tab === 1
                    ? ButtonSwitchersRoundedSmBasicHTML.default
                    : ButtonSwitchersRoundedSmBasicJsx.default
                }
                ButtonSwitchersRoundedSmBasicLeadingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonSwitchersRoundedSmBasicLeadingIconHTML.default
                    : ButtonSwitchersRoundedSmBasicLeadingIconJsx.default
                }
                ButtonSwitchersRoundedSmBasicTrailingIcon={
                  activeTabs[0].active_tab === 1
                    ? ButtonSwitchersRoundedSmBasicTrailingIconHTML.default
                    : ButtonSwitchersRoundedSmBasicTrailingIconJsx.default
                }
              />
            </div>
          </PreviewBlock>

          <h3 id="RoundedButtonSwitchers">Fully rounded button switchers</h3>

          <PreviewBlock
            id="RoundedButtonSwitchers"
            getActiveTabs={getActiveTabs}
            HtmlComponent={ButtonSwitchersFullyRoundedLgBasicHTML.default}
            JsxComponent={ButtonSwitchersFullyRoundedLgBasicJsx.default}
          >
            <div className="flex w-full items-center justify-center overflow-scroll rounded-xl bg-white px-4 py-16 md:overflow-hidden">
              <AllRoundedFullButtonSwitchers
                ButtonSwitchersFullyRoundedLgBasic={
                  activeTabs[1].active_tab === 1
                    ? ButtonSwitchersFullyRoundedLgBasicHTML.default
                    : ButtonSwitchersFullyRoundedLgBasicJsx.default
                }
                ButtonSwitchersFullyRoundedLgBasicLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonSwitchersFullyRoundedLgBasicLeadingIconHTML.default
                    : ButtonSwitchersFullyRoundedLgBasicLeadingIconJsx.default
                }
                ButtonSwitchersFullyRoundedLgBasicTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonSwitchersFullyRoundedLgBasicTrailingIconHTML.default
                    : ButtonSwitchersFullyRoundedLgBasicTrailingIconJsx.default
                }
                ButtonSwitchersFullyRoundedBaseBasic={
                  activeTabs[1].active_tab === 1
                    ? ButtonSwitchersFullyRoundedBaseBasicHTML.default
                    : ButtonSwitchersFullyRoundedBaseBasicJsx.default
                }
                ButtonSwitchersFullyRoundedBaseBasicLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonSwitchersFullyRoundedBaseBasicLeadingIconHTML.default
                    : ButtonSwitchersFullyRoundedBaseBasicLeadingIconJsx.default
                }
                ButtonSwitchersFullyRoundedBaseBasicTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonSwitchersFullyRoundedBaseBasicTrailingIconHTML.default
                    : ButtonSwitchersFullyRoundedBaseBasicTrailingIconJsx.default
                }
                ButtonSwitchersFullyRoundedSmBasic={
                  activeTabs[1].active_tab === 1
                    ? ButtonSwitchersFullyRoundedSmBasicHTML.default
                    : ButtonSwitchersFullyRoundedSmBasicJsx.default
                }
                ButtonSwitchersFullyRoundedSmBasicLeadingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonSwitchersFullyRoundedSmBasicLeadingIconHTML.default
                    : ButtonSwitchersFullyRoundedSmBasicLeadingIconJsx.default
                }
                ButtonSwitchersFullyRoundedSmBasicTrailingIcon={
                  activeTabs[1].active_tab === 1
                    ? ButtonSwitchersFullyRoundedSmBasicTrailingIconHTML.default
                    : ButtonSwitchersFullyRoundedSmBasicTrailingIconJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Button switchers can have two segments. Each segment is clearly
            divided and contains label text, an icon, or both.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            Button switchers come in 6 styles (basic, leading icon, trailing
            icon, basic rounded, leading icon rounded and trailing icon rounded)
            They can contain text, leading or trailing icons. All variations
            come in 3 sizes:
          </p>
          <ul>
            <li>
              <strong>Small:</strong> 2rem height (32px), 1rem (16px) horizontal
              padding and 0.75rem (12px) font size. Icons on small buttons are
              1rem (16px) wide and tall and have a 0.5rem (8px) distance from
              the text.
            </li>
            <li>
              <strong>Base:</strong> 2.5rem height (40px), 1.25rem (20px)
              horizontal padding and 0.875rem (14px) font size. Icons on base
              buttons are 1.25rem (20px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
            <li>
              <strong>Large:</strong> 3rem height (48px), 1.5rem (24px)
              horizontal padding and 0.875rem (14px) font size. Icons on large
              buttons are 1.25rem (20px) wide and tall and have a 0.5rem (8px)
              distance from the text.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Button switchers are built with the native{" "}
            <code>&lt;input type=&quot;checkbox&quot;&gt;</code> element. Two
            labels to express the states are used and point to the same input
            element.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
